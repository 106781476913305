<template>
<div>

	<post1 :accroche="$t('label_strategy')"
				 date="12/09/2021"
				 auteur="Nicolat L."
				 :fonction="$t('property_analyst')"
				 avatar="1846"
				 comments=""
				 like=""
				 :xml="selected_xml"
				 authorinfo="" />
</div>
</template>


<script>
import post1 from "@/components/post1.vue";

export default {
	name: 'strategie',
	components: {
		post1
	},
	props: {},
	data: () => ({

		xml_fr: `

        <?xml version="1.0"?>
        <blogs>
        <titre> La stratégie d'investissement immobilier </titre>
        <soustitre>L'immobilier que nous achetons, louons et revendons  </soustitre>
        <illustration>https://media.berlcoin.fr/18.jpg</illustration>

        <texte>
        Une stratégie urbaine de réinvestissement continu

    Nous achetons des biens immobiliers au coeur de Berlin dans un 1er temps, puis dans des centres urbains de capitales nationales ou régionales dans la zone euro avec comme objectif de dégager des plus-values importantes lors de la revente;

    Spéculation

    Le critère prioritaire lors de la sélection des biens immobiliers est le potentiel de valorisation à 10 ans : le choix de l'adresse, sa centralité et la qualité du bâtiment sont primordiaux.

    Optimisation fiscale

    La fiscalité est le second critère de choix, c'est pourquoi nous investissons en Allemagne :
    - Il n'y a pas de taxe sur les plus-values après 10 ans de possession.
    - Les taxes sur l'immobilier sont globalement très faibles, autour de 10%.

    Nous pouvons donc réinvestir la quasi-totalité des revenus et plus-value dans d'autres biens immobiliers et accroître les performances du berlcoin.

    Pression locative

    Afin d'optimiser les rendements locatifs, nous privilégions les zones très tendues, des baux à durée définie (de 1 à 5 ans), des colocations ou de la location meublée. En Allemagne, Berlin est actuellement la ville la plus intéressante avec près de 85% de locataires et un très fort taux d'occupation.

    </texte>



          <category>
              <libelle>Allemagne</libelle>
            <cat_id>AL</cat_id>

            <blog>
              <titre>L'Allemagne</titre>
              <texte>
              L'Allemagne en général et Berlin en particulier connaissent depuis plusieurs années une forte croissance sur le marché immobilier et attirent des investisseurs du monde entier, notamment grâce à des prix attractifs.

    L´Allemagne est la première économie d’Europe. De par sa stabilité budgétaire et son attractivité, Berlin représente un placement sûr pour un investissement en zone Euro.

    L'Allemagne est une économie très forte qui possède une balance commerciale positive et de nombreux excédents budgétaires. Ces derniers lui permettent de relancer son économie même en cas de crise.

    Ainsi, l'Allemagne grâce à son économie et la maitrise de sa dette dispose d'un marché immobilier considéré comme  valeur refuge par de nombreux investisseurs internationaux.
              </texte>
            </blog>


                    <blog>
                      <titre>L'Allemagne est résiliente lors des crises</titre>
                      <texte>
                      Que ce soit en période de croissance ou de crise, l’immobilier allemand et plus particulièrement berlinois représente une opportunité d’investissement très intéressante.

    En période de croissance, la tendance à migrer vers les villes et les grandes capitales est une tendance de fond qui n’est structurellement pas prête de s’inverser malgré des aléas conjoncturels liés au covid.

    En période de crise, la pierre est une valeur refuge et la capitale allemande est très attractive. L’Allemagne dispose de beaucoup d’excédents budgétaires et sera en mesure de relancer et soutenir son économie.

    Le marché immobilier restera donc soutenu quelle que soit la conjoncture économique.

                      </texte>
                    </blog>
                    <blog>
                      <titre>L'Allemagne permet de diversifier vos investissements</titre>
                      <texte>


                        La diversification est un des principes clés de toute gestion de patrimoine.

                        Réaliser des investissements immobiliers en Allemagne permet donc de diversifier son portefeuille d’investissement et de réduire les risques.
                      </texte>
                    </blog>
          </category>
          <category>
          <libelle>Berlin</libelle>
            <cat_id>BL</cat_id>

            <blog>
              <titre>Berlin est attractive</titre>
              <texte>
              Le marché berlinois attire ainsi de nombreux investisseurs internationaux tels que les Américains et les Chinois.
    Par exemple, le magnat américain Warren Buffet a investi plusieurs millions d’euros dans l’immobilier berlinois depuis 2015.

    Berlin attire de plus en plus de start-ups et de talents internationaux avec de hauts revenus.
    L’ouverture de la gigafactory de Tesla à Berlin reflète bien l’attrait de la ville dopé par l'ouverture de son nouvel aéroport.

    Ces start-ups favorisent la hausse des loyers et la gentrification de Berlin. Le phénomène est également amplifié par le marché de l’immobilier de bureau qui est en forte croissance. Il possède lui aussi une très bonne rentabilité et une demande croissante. La multiplication des bureaux dans les quartiers historiques de Berlin crée une tension sur le marché locatif résidentiel à cause des réaffectations d'usage. Cela génère une pénurie de logements qui favorise la hausse générale de l’immobilier.

    Par ailleurs, les start-ups attirent de nombreux cadres supérieurs qui viennent de Paris, Londres, Amsterdam ou encore Singapour. Ces expatriés ont l’habitude de payer des loyers importants. Ils sont également nombreux à vouloir acquérir un bien immobilier. Cela vient ainsi renchérir le prix de l’immobilier berlinois, notamment les appartements meublés en location de courte durée (1 à 5 ans).
              </texte>
            </blog>



            <blog>

              <titre>Dans quels quartiers investissons-nous prioritairement à Berlin ?</titre>
              <texte>
                Aujourd'hui Berlin est toujours symboliquement divisée en deux parties, Berlin Est et Berlin Ouest, composées de 12 quartiers. Pour investir en immobilier à Berlin nous privilégions les quartiers centraux qui sont les plus recherchés et offrent le potentiel de plus-value le plus fort:

                Mitte
                Mitte est le quartier le plus central de Berlin, réputé pour ses attractions et monuments (comme la tour de télévision de l'Alexanderplatz et la porte de Brandebourg).
                Le quartier de Mitte est également connu pour l'Ile aux musées, regroupant cinq musées au bord de la Spree, et la place Gendarmenmarkt, très fréquentée par les Berlinois et les touristes.
                On y retrouve le marché de Noël en hiver et des concerts en plein air l'été.

                Prenzlauer Berg
                Prenzlauer Berg est considéré comme le quartier résidentiel le plus réputé de Berlin.
                On y trouve le mémorial du mur de Berlin et le Mauerpark pour les amateurs d'histoire ainsi que le célèbre marché aux puces.
                Prenzlauer Berg est aussi connu pour ses places verdoyantes et animées, comme la place Kollwitzplatz, où se trouve l’un des marchés les plus connus de Berlin, ainsi que la Helmholtzplatz.
                La Kastanienallee est l'une des rues les plus prisées de Prenzlauer Berg, entourée de bars, boutiques fashion ou encore de magasins de créateurs de mode.

                Kreuzberg
                Kreuzberg est reconnu depuis un certain temps comme le quartier le plus cosmopolite de Berlin.
                En se baladant dans le quartier de Kreuzberg, on peut y voir de nombreux lieux de street art berlinois.
                Le quartier de Kreuzberg offre de nombreux parcs, comme le Viktoria Park avec une vue panoramique de la ville.
                Kreuzberg est le lieu préféré des musiciens et des artistes du monde entier se retrouvant dans de nombreux bars et clubs.

                Friedrichshain
                Friedrichshain, un des quartiers de Berlin Est et proche du quartier Mitte, est connu pour sa grande avenue Karl Marx Allee, longue de plus de 2,6 km.
                Friedrichshain est le seul quartier de Berlin construit dans le style stalinien.
                Ce quartier est dynamique et apprécié des jeunes notamment avec le Volkspark, où l'on peut se retrouver pour des activités en plein air ou pour faire un pique-nique, et la rue Simon-Dach-Straße, entourée de nombreux pubs à la mode et restaurants.
                Il y a également l´East Side Gallery, le plus long morceau du mur de Berlin encore debout.
                C’est un lieu très visité par les touristes tout au long de l´année.

                Neukölln
                Au sud de Berlin, Neukölln est un des quartiers très multiculturels de Berlin, qui se développe rapidement notamment grâce à sa proximité avec Kreuzberg et son atmosphère artistique unique.
                C´est un quartier jeune et animé, très apprécié par la communauté de jeunes expatriés français.

                Schöneberg
                Dans le quartier éclectique de Schöneberg, les boutiques et les cafés situés dans la rue Motzstraße et autour de la place Viktoria-Luise-Platz sont appréciés de l'importante communauté gay.
                L'immense Kaufhaus des Westens, un grand magasin surnommé KaDeWe, attire une clientèle chic, et les gourmets se rendent à l'étage dédié à la gastronomie.
                La Potsdamer Strasse est parsemée de galeries originales ainsi que d'épiceries et restaurants turcs. Les familles aiment se promener dans le vaste parc Südgelände, sur le site d'une ancienne gare de triage.

                Charlottenburg
                Avec ses élégants bâtiments d'avant-guerre et ses boutiques chics, le quartier de Charlottenbourg, à l'ouest de Berlin, est réputé pour son atmosphère sophistiquée.
                Le boulevard commerçant Kurfürstendamm ("Ku'damm") est bordé d'hôtels et de grands magasins. Le château de Charlottenbourg présente un intérieur de style baroque ainsi que des jardins paysagers.
                Savignyplatz, centre littéraire et d'arts du spectacle datant d'avant la Seconde Guerre mondiale, est aujourd'hui entouré de restaurants asiatiques qui attirent une clientèle internationale.

              </texte>
            </blog>


            </category>
            <category>
            <libelle>Prix et fiscalité</libelle>
            <cat_id>PF</cat_id>



            <blog>
              <titre>

                Quels sont les prix immobiliers à Berlin ?</titre>
                <texte>

                  Les prix de l’immobilier à Berlin sont faibles pour une capitale : jusqu'à 3 fois moins chers que Paris et 5 fois moins chers que Londres.

                  Les prix de l´immobilier à Berlin sont en constante évolution de 9 à 10% par an, avec pour cause principale un fort accroissement de la population et un taux d'occupation des logements de 98%.

                  Les prix immobiliers à Berlin se situent autour de 7000 euros par m2 dans les quartiers centraux dans lesquels nous investissons et varient selon les quartiers entre 6000 et 9000 euros.

                </texte>
              </blog>
              <blog>
                <titre>Quelle fiscalité pour l'achat d'un bien immobilier à Berlin ?</titre>
                  <texte>
                  La fiscalité immobilière à Berlin est très attractive pour les investisseurs.

    Pour l’achat d’un appartement à Berlin, les intérêts d'emprunt immobilier et les charges du coût d'achat sont déductibles des revenus locatifs (les coûts de revient comprennent : le prix d'achat, les frais de notaire et d'agence et les déplacements liés à l'achat immobilier à Berlin), ainsi qu’un amortissement du bien allant jusqu'à 2,5% par an.

    Grâce à ce système de déductions, le taux moyen d’imposition sur les revenus locatifs est souvent inférieur à 10%.

    En outre, à Berlin, il n’y pas de taxe d’habitation et la taxe foncière est très faible (moins de 100€ pour un studio et moins de 150€ pour un deux-pièces de 50m2).

    Ensuite, aucune cotisation sociale n’est due, contrairement aux 17,2% de prélèvements sociaux dans l’Hexagone.

    Un autre avantage d'investir à Berlin est l'exonération de la plus-value immobilière au bout de 10 ans de détention du bien.
                    </texte>
                  </blog>
                  <blog>
                    <titre>Pression locative, fiscalité attractive et plus-value à long terme</titre>
                      <texte>
                      Berlin se démarque de la moyenne allemande avec un taux de propriétaires de 15 % seulement (contre 46 % sur l'ensemble de l'Allemagne). De ce fait, la demande locative est très importante.

     Investir dans l'immobilier de la capitale allemande permet d'augmenter la valeur de son patrimoine sur du long terme et d'optimiser son imposition fiscale.

     C´est donc le bon moment d´investir à Berlin puisque que dans les années à venir la capitale rattrapera les prix des principales capitales européennes et les biens prendront inévitablement de la valeur.

                  </texte>
                </blog>
              </category>
            </blogs>

        `,

		xml_en: `

        <?xml version="1.0"?>
        <blogs>
        <titre> The property investment strategy </titre>
        <soustitre>The real estate we buy, rent and resell </soustitre>
        <illustration>https://media.berlcoin.fr/18.jpg</illustration>



        <texte>
				An urban strategy of continuous reinvestment

			We buy properties in the heart of Berlin initially, then in urban centres of national or regional capitals in the eurozone with the aim of generating significant capital gains on resale;

			Speculation

			The main criterion for the selection of properties is the potential for appreciation over a 10-year period: the choice of address, its centrality and the quality of the building are of prime importance.

			Tax optimisation

			Tax is the second most important criterion, which is why we invest in Germany:
			- There is no capital gains tax after 10 years of ownership.
			- Taxes on real estate are very low overall, around 10%.

			We can therefore reinvest almost all the income and capital gains in other properties and increase the performance of the berlcoin.

			Rental pressure

			In order to optimise rental yields, we favour high pressure areas, fixed term leases (1-5 years), co-location or furnished rental. In Germany, Berlin is currently the most interesting city with almost 85% of tenants and a very high occupancy rate.


    </texte>



          <category>
              <libelle>Germany</libelle>
            <cat_id>AL</cat_id>

            <blog>
              <titre>Germany</titre>
              <texte>
							Germany in general and Berlin in particular have been experiencing strong growth in the property market for several years and attracting investors from all over the world, not least because of attractive prices.

				    Germany is Europe's largest economy. Due to its budgetary stability and attractiveness, Berlin is a safe place to invest in the Eurozone.

				    Germany is a very strong economy with a positive trade balance and numerous budget surpluses. These surpluses allow the country to boost its economy even in times of crisis.

				    Thus, Germany thanks to its economy and the control of its debt has a real estate market considered as a safe haven by many international investors.

              </texte>
            </blog>


                    <blog>
                      <titre>Germany is resilient during crises</titre>
                      <texte>
											Whether in times of growth or crisis, German and especially Berlin real estate represents a very interesting investment opportunity.

					In times of growth, the tendency to migrate to cities and large capitals is a basic trend that is structurally not about to be reversed despite cyclical vagaries related to covid.

					In times of crisis, real estate is a safe haven and the German capital is very attractive. Germany has a large budget surplus and will be able to revive and support its economy.

					The property market will therefore remain strong regardless of the economic situation.


                      </texte>
                    </blog>
                    <blog>
                      <titre>Germany allows you to diversify your investments</titre>
                      <texte>


											Diversification is one of the key principles of any wealth management.

															Making real estate investments in Germany therefore allows you to diversify your investment portfolio and reduce your risks.
                      </texte>
                    </blog>
          </category>
          <category>
          <libelle>Berlin</libelle>
            <cat_id>BL</cat_id>

            <blog>
              <titre>Berlin is attractive</titre>
              <texte>
							The Berlin market thus attracts many international investors such as Americans and Chinese.
		For example, American tycoon Warren Buffet has invested several million euros in Berlin real estate since 2015.

		Berlin is attracting more and more start-ups and international talent with high incomes.
		The opening of Tesla's gigafactory in Berlin reflects the city's attractiveness boosted by the opening of its new airport.

		These start-ups are driving up rents and gentrification in Berlin. The phenomenon is also amplified by the rapidly growing office property market. It too is highly profitable and in increasing demand. The increase in the number of offices in Berlin's historic districts is putting pressure on the residential rental market due to reuse. This is creating a shortage of housing, which is driving up the overall price of real estate.

						    In addition, start-ups attract many senior executives from Paris, London, Amsterdam and Singapore. These expatriates are used to paying high rents. Many of them also want to buy a property. This increases the price of Berlin real estate, especially furnished flats for short-term rental (1 to 5 years).
								              </texte>
            </blog>



            <blog>

              <titre>In which neighbourhoods do we prioritise investing in Berlin?</titre>
              <texte>
                Aujourd'hui Berlin est toujours symboliquement divisée en deux parties, Berlin Est et Berlin Ouest, composées de 12 quartiers. Pour investir en immobilier à Berlin nous privilégions les quartiers centraux qui sont les plus recherchés et offrent le potentiel de plus-value le plus fort:

								Today Berlin is still symbolically divided into two parts, East Berlin and West Berlin, consisting of 12 districts. To invest in real estate in Berlin we prioritise the central districts, which are the most sought-after and offer the highest potential for added value:

				                Mitte
				                Mitte is the most central district of Berlin, known for its attractions and monuments (such as the Alexanderplatz television tower and the Brandenburg Gate).
				                Mitte is also known for the Museum Island, with five museums on the banks of the Spree, and the Gendarmenmarkt square, which is popular with Berliners and tourists.
				                It is home to the Christmas market in winter and open-air concerts in summer.

				                Prenzlauer Berg
				                Prenzlauer Berg is considered Berlin's most famous residential area.
				                It is home to the Berlin Wall Memorial and the Mauerpark for history buffs as well as the famous flea market.
				                Prenzlauer Berg is also known for its green and lively squares, such as Kollwitzplatz, home to one of Berlin's most famous markets, and Helmholtzplatz.
				                Kastanienallee is one of the most popular streets in Prenzlauer Berg, surrounded by bars, fashion boutiques and designer shops.

				                Kreuzberg
				                Kreuzberg has been known for some time as the most cosmopolitan district in Berlin.
				                Walking around Kreuzberg, you can see many of Berlin's street art venues.
				                The Kreuzberg district offers many parks, such as the Viktoria Park with a panoramic view of the city.
				                Kreuzberg is a favourite place for musicians and artists from all over the world to meet in many bars and clubs.

				                Friedrichshain
				                Friedrichshain, one of the districts of East Berlin and close to the Mitte district, is known for its grand Karl Marx Allee, which is over 2.6 km long.
				                Friedrichshain is the only district in Berlin built in the Stalinist style.
				                It is a vibrant and youthful area with the Volkspark, where you can meet for outdoor activities and picnics, and Simon-Dach-Straße, surrounded by many trendy pubs and restaurants.
				                There is also the East Side Gallery, the longest piece of the Berlin Wall still standing.
				                It is a popular place for tourists throughout the year.

				                Neukölln
				                In the south of Berlin, Neukölln is one of Berlin's most multicultural neighbourhoods, which is developing rapidly thanks to its proximity to Kreuzberg and its unique artistic atmosphere.
				                It is a young and lively district, very popular with the young French expatriate community.

				                Schöneberg
				                In the eclectic Schöneberg district, the shops and cafés on Motzstraße and around Viktoria-Luise-Platz are popular with the large gay community.
				                The huge Kaufhaus des Westens, a department store nicknamed KaDeWe, attracts a chic clientele, and gourmets flock to the gourmet floor.
				                Potsdamer Strasse is dotted with original galleries and Turkish grocery shops and restaurants. Families love to stroll through the extensive Südgelände Park, on the site of a former marshalling yard.

												Charlottenburg
										                With its elegant pre-war buildings and chic boutiques, the Charlottenburg district in western Berlin is renowned for its sophisticated atmosphere.
										                The Kurfürstendamm ("Ku'damm") shopping boulevard is lined with hotels and department stores. Charlottenburg Palace has a baroque interior and landscaped gardens.
										                Savignyplatz, a literary and performing arts centre dating from before the Second World War, is now surrounded by Asian restaurants that attract an international clientele.
              </texte>
            </blog>


            </category>
            <category>
            <libelle>Prices and taxation</libelle>
            <cat_id>PF</cat_id>



            <blog>
              <titre>

                What are the property prices in Berlin?</titre>
                <texte>

								Property prices in Berlin are low for a capital city: up to 3 times cheaper than Paris and 5 times cheaper than London.

		 				                  Property prices in Berlin are constantly rising by 9-10% per year, mainly due to a strong population growth and a 98% occupancy rate of housing.

		 				                  Property prices in Berlin are around 7000 euros per m2 in the central districts in which we invest and vary according to the districts between 6000 and 9000 euros.
                </texte>
              </blog>
              <blog>
                <titre>What is the taxation for buying a property in Berlin?</titre>
                  <texte>
									Berlin's real estate taxation is very attractive for investors.

				    When buying a flat in Berlin, the interest on the mortgage and the costs of the purchase are deductible from the rental income (the costs of the purchase include: the purchase price, the notary and agency fees and the travel costs related to the purchase of real estate in Berlin), as well as a depreciation of the property of up to 2.5% per year.

				    Thanks to this system of deductions, the average tax rate on rental income is often below 10%.

				    Furthermore, in Berlin there is no council tax and the property tax is very low (less than 100€ for a studio and less than 150€ for a 50m2 two-room apartment).

				    Secondly, no social security contributions are due, unlike the 17.2% social security levies in France.

				    Another advantage of investing in Berlin is the exemption from capital gains tax after 10 years of ownership.
                    </texte>
                  </blog>
                  <blog>
                    <titre>Rental pressure, attractive taxation and long-term capital gains</titre>
                      <texte>
											Berlin stands out from the German average with a homeownership rate of only 15% (compared to 46% in Germany as a whole). As a result, rental demand is very high.

				     Investing in real estate in the German capital allows you to increase the value of your assets over the long term and to optimise your tax liability.

				     It is therefore the right time to invest in Berlin since in the coming years the capital will catch up with the prices of the main European capitals and the properties will inevitably increase in value.
                  </texte>
                </blog>
              </category>
            </blogs>

        `,


						xml_de: `

				        <?xml version="1.0"?>
				        <blogs>
				        <titre> La stratégie d'investissement immobilier </titre>
				        <soustitre>L'immobilier que nous achetons, louons et revendons  </soustitre>
				        <illustration>https://media.berlcoin.fr/18.jpg</illustration>



				        <texte>
								Wir kaufen Immobilien zunächst im Herzen Berlins und später in Stadtzentren von nationalen oder regionalen Hauptstädten in der Eurozone mit dem Ziel, beim Wiederverkauf hohe Wertsteigerungen zu erzielen;

 Spekulation

 Das vorrangige Kriterium bei der Auswahl von Immobilien ist das Wertsteigerungspotenzial in 10 Jahren: Die Wahl der Adresse, ihre Zentralität und die Qualität des Gebäudes sind von größter Bedeutung.

 Steuerliche Optimierung

 Die Besteuerung ist das zweitwichtigste Kriterium, weshalb wir in Deutschland investieren:
 - Es gibt keine Kapitalertragssteuer nach 10 Jahren Besitz.
 - Die Steuern auf Immobilien sind insgesamt sehr niedrig und liegen bei etwa 10 %.

 Wir können also fast alle Erträge und Kapitalgewinne in andere Immobilien reinvestieren und die Performance des Berlcoin steigern.

 Druck auf die Mieten

 Um die Mietrenditen zu optimieren, bevorzugen wir sehr angespannte Gegenden, Mietverträge mit einer bestimmten Laufzeit (1 bis 5 Jahre), Wohngemeinschaften oder möblierte Mieten. In Deutschland ist Berlin derzeit die interessanteste Stadt mit fast 85% Mietern und einer sehr hohen Belegungsquote.

				    </texte>



				          <category>
				              <libelle>Deutschland</libelle>
				            <cat_id>AL</cat_id>

				            <blog>
				              <titre>Deutschland</titre>
				              <texte>
											Deutschland im Allgemeinen und Berlin im Besonderen verzeichnen seit einigen Jahren ein starkes Wachstum auf dem Immobilienmarkt und ziehen Investoren aus der ganzen Welt an, nicht zuletzt aufgrund der attraktiven Preise.

Deutschland ist die größte Volkswirtschaft in Europa. Aufgrund seiner Haushaltsstabilität und seiner Attraktivität stellt Berlin eine sichere Anlage für eine Investition in der Eurozone dar.

Deutschland ist eine sehr starke Volkswirtschaft mit einer positiven Handelsbilanz und zahlreichen Haushaltsüberschüssen. Letztere ermöglichen es ihm, seine Wirtschaft selbst im Falle einer Krise anzukurbeln.

So verfügt Deutschland dank seiner Wirtschaft und der Kontrolle seiner Schulden über einen Immobilienmarkt, der von vielen internationalen Investoren als sicherer Hafen angesehen wird.
				              </texte>
				            </blog>


				                    <blog>
				                      <titre>Deutschland ist krisenresistent</titre>
				                      <texte>
															Sowohl in Wachstums- als auch in Krisenzeiten stellen deutsche und insbesondere Berliner Immobilien eine sehr interessante Investitionsmöglichkeit dar.

    In Wachstumszeiten ist die Tendenz, in die Städte und großen Hauptstädte zu ziehen, ein Grundtrend, der sich trotz konjunktureller Unwägbarkeiten aufgrund des Covid strukturell nicht umkehren wird.

    In Krisenzeiten ist der Stein ein sicherer Hafen und die deutsche Hauptstadt ist sehr attraktiv. Deutschland verfügt über große Haushaltsüberschüsse und wird in der Lage sein, seine Wirtschaft anzukurbeln und zu stützen.

    Der Immobilienmarkt wird daher unabhängig von der Wirtschaftslage weiterhin unterstützt werden.

				                      </texte>
				                    </blog>
				                    <blog>
				                      <titre>Deutschland ermöglicht es Ihnen, Ihre Investitionen zu diversifizieren</titre>
				                      <texte>


															Diversifizierung ist eines der Schlüsselprinzipien jeder Vermögensverwaltung.

											 In Deutschland in Immobilien zu investieren, ermöglicht es daher, das Investitionsportfolio zu diversifizieren und das Risiko zu reduzieren.
				                      </texte>
				                    </blog>
				          </category>
				          <category>
				          <libelle>Berlin</libelle>
				            <cat_id>BL</cat_id>

				            <blog>
				              <titre>Berlin ist attraktiv</titre>
				              <texte>
											Der Berliner Markt zieht daher viele internationale Investoren wie Amerikaner und Chinesen an.
							     Beispielsweise hat der amerikanische Magnat Warren Buffet seit 2015 mehrere Millionen Euro in Berliner Immobilien investiert.

							     Berlin zieht immer mehr Start-ups und internationale Talente mit hohen Einkommen an.
							     Die Eröffnung der Tesla-Gigafactory in Berlin spiegelt die Attraktivität der Stadt, die durch die Eröffnung des neuen Flughafens angekurbelt wird, gut wider.

							     Diese Start-ups fördern den Anstieg der Mieten und die Gentrifizierung Berlins. Dieses Phänomen wird auch durch den stark wachsenden Markt für Büroimmobilien verstärkt. Auch er verfügt über eine sehr gute Rentabilität und eine steigende Nachfrage. Die Zunahme von Büros in den historischen Bezirken Berlins führt aufgrund von Umnutzungen zu Spannungen auf dem Wohnmietmarkt. Dies erzeugt eine Wohnungsknappheit, die den allgemeinen Anstieg der Immobilienpreise begünstigt.

							     Darüber hinaus ziehen Start-ups viele Führungskräfte an, die aus Paris, London, Amsterdam oder Singapur kommen. Diese Expatriates sind es gewohnt, hohe Mieten zu zahlen. Viele von ihnen sind auch daran interessiert, eine Immobilie zu erwerben. Dies verteuert somit die Preise für Berliner Immobilien, insbesondere für möblierte Wohnungen zur Kurzzeitmiete (1 bis 5 Jahre).
									 				              </texte>
				            </blog>



				            <blog>

				              <titre>In welche Bezirke investieren wir in Berlin vorrangig? </titre>
				              <texte>
				         Berlin ist auch heute noch symbolisch in Ost- und West-Berlin geteilt, die sich aus 12 Stadtteilen zusammensetzen. Um in Immobilien in Berlin zu investieren, bevorzugen wir die zentralen Bezirke, die am begehrtesten sind und das größte Wertsteigerungspotenzial bieten:
				                Mitte

				              </texte>
				            </blog>


				            </category>
				            <category>
				            <libelle>Prix et fiscalité</libelle>
				            <cat_id>PF</cat_id>




				              </category>
				            </blogs>

				        `,

				selected_xml: "",

			}),
			beforeCreate: function () {},
			created: function () {},
			beforeMount: function () {},
			mounted: function () {
				this.$i18n.locale = this.$route.params.lang
				this.langxml();
			},
			beforeUpdate: function () {},
			updated: function () {

			},
			beforeDestroy: function () {},
			destroyed: function () {},
			computed: {
				locale() {
					return this.$i18n.locale;
				},
			},
			methods: {
				langxml() {
					if (this.$i18n.locale == "fr") this.selected_xml = this.xml_fr;
					if (this.$i18n.locale == "en") this.selected_xml = this.xml_en;
					if (this.$i18n.locale == "de") this.selected_xml = this.xml_de;

				}
			},
			watch: {
				locale() {
					this.langxml();
				},
			}
			}
			</script>

			<style scoped>
			</style>
